import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import React, { useCallback, useEffect, useState } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { TbCamera } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ERR_MSGS } from "../../constants/errorMsgs";

import { jwtDecode } from "jwt-decode";
import Input, { isPossiblePhoneNumber } from "react-phone-number-input/input";
import { useSelector } from "react-redux";
import RequiredAsterisk from "../../components/ui/icons/RequiredAsterisk";
import { googleAPIKey } from "../../constants/environment.constants";
import {
  initCenterRegistrationErrorForm,
  initCenterRegistrationForm,
} from "../../constants/initialForms";
import { QUERY_PARAMS_KEY } from "../../constants/keys";
import { regexPatterns } from "../../constants/regexPatterns";
import {
  centerRegistration,
  centerUpdate,
  completeProfile,
  fetchAccountInfo,
  postFiles,
  verifyEmail,
} from "../../services/auth.service";
import { RootState } from "../../store/store";
import { ICenterRegistrationForm } from "../../types/IRegistration";
import { convertToE164 } from "../../utils/functions";

const CompleteCenterRegistration = () => {
  const [completeCenterForm, setCompleteCenterForm] =
    useState<ICenterRegistrationForm>(initCenterRegistrationForm);
  const [completeCenterFormErrors, setCompleteCenterFormErrors] = useState(
    initCenterRegistrationErrorForm
  );
  const [authCode, setAuthCode] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [adminImage, setAdminImage] = useState<File | null>(null);
  const [centerImages, setCenterImages] = useState<File[]>([]);
  const [adminPreviewUrl, setAdminPreviewUrl] = useState("");
  const [centerPreviewUrls, setCenterPreviewUrls] = useState([""]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [autoComplete, setAutoComplete] =
    useState<google.maps.places.Autocomplete>();
  const [imageDetails, setImageDetails] = useState<any>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const redirectURI = urlParams.get("redirect_uri");
  const cancelURI = urlParams.get("cancel");
  const paramToken = urlParams.get("token");
  const clientID = urlParams.get(QUERY_PARAMS_KEY.CLIENT_ID);
  const { User } = useSelector((state: RootState) => state?.auth);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleAPIKey,
    libraries: ["places"],
  });

  // const inputRef = useRef<HTMLInputElement>(null);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     const inputElement = inputRef.current?.querySelector('input');
  //     if (inputElement) {
  //       inputElement.setAttribute('autocomplete', 'none');
  //     }
  //   }, 100); // Slight delay to ensure the input element is rendered

  //   return () => clearTimeout(timer);
  // }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event?.target;
    if (name === "postalCode" && !regexPatterns.postalCode.test(value)) {
      return;
    }
    setCompleteCenterForm((prev) => ({ ...prev, [name]: value }));
    // setCompleteProfileFormErrors((prev) => ({ ...prev, createAccountError: false }));
  };

  const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event?.target;
    if (name === "confirmPassword") {
      setCompleteCenterFormErrors((prev) => ({
        ...prev,
        confirmPasswordError: "",
        // createAccountError: false,
      }));
      return;
    }

    const fieldMappings: { [key: string]: string } = {
      adminName: "adminNameError",
      adminPhoneNumber: "adminPhoneNumberError",
      centerName: "centerNameError",
      centerPhoneNumber: "centerPhoneNumberError",
      imageUrls: "imageUrlsError",
      coordinates: "coordinatesError",
      centerAddr: "addressError",
      city: "cityError",
      state: "stateError",
      postalCode: "postalCodeError",
      webUrl: "webUrlError",
      email: "emailError",
      password: "passwordError",
    };
    const errorKey = fieldMappings[name];
    if (errorKey) {
      setCompleteCenterFormErrors((prev) => ({
        ...prev,
        [errorKey]: "",
      }));
    }
  };

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event?.target;
    const fieldMappings: { [key: string]: { key: string; errorMsg: string } } =
    {
      centerName: {
        key: "centerNameError",
        errorMsg: ERR_MSGS?.Center_Name_Length,
      },
      centerPhoneNumber: {
        key: "phoneNumberError",
        errorMsg: ERR_MSGS.Phone_Number_Length,
      },
      postalCode: {
        key: "postalCodeError",
        errorMsg: ERR_MSGS?.Postal_Code,
      },
      adminName: {
        key: "adminNameError",
        errorMsg: ERR_MSGS?.Name_length,
      },
      adminPhoneNumber: {
        key: "adminPhoneNumberError",
        errorMsg: ERR_MSGS.Phone_Number_Length,
      },
      webUrl: {
        key: "webUrlError",
        errorMsg: ERR_MSGS.Invalid_Web_Url,
      },
      email: {
        key: "emailError",
        errorMsg: ERR_MSGS?.Invalid_Email,
      },
      password: {
        key: "passwordError",
        errorMsg: ERR_MSGS.Invalid_Password_Pattern,
      },
    };

    if (
      name === "confirmPassword" &&
      completeCenterForm?.confirmPassword !== completeCenterForm?.password
    ) {
      setCompleteCenterFormErrors((prev) => ({
        ...prev,
        confirmPasswordError: ERR_MSGS.Password_Does_Not_Match,
      }));
    }

    const fieldMapping = fieldMappings[name];
    if (fieldMapping) {
      const { key, errorMsg } = fieldMapping;
      const { value } = event?.target;
      const regexPatternFind: { [key: string]: RegExp } = {
        centerName: regexPatterns?.centerName,
        centerPhoneNumber: regexPatterns?.phoneNumber,
        postalCode: regexPatterns?.postalCodeLength,
        adminName: regexPatterns?.name,
        adminPhoneNumber: regexPatterns?.phoneNumber,
        webUrl: regexPatterns?.webUrl,
        email: regexPatterns?.email,
        password: regexPatterns?.updatePassword,
        // city: regexPatterns?.city,
      };
      if (name === "webUrl" && !value) return;

      if (name in regexPatternFind && !regexPatternFind[name].test(value)) {
        setCompleteCenterFormErrors((prev) => ({ ...prev, [key]: errorMsg }));
      }
    }
  };

  const uploadAdminImage = async () => {
    const formData = new FormData();
    formData.append("files", adminImage as File);
    const res: any = await postFiles(formData);
    if (res?.ok) {
      setCompleteCenterForm((prev) => ({
        ...prev,
        adminProfilePictureUrl: res?.data?.urls[0],
      }));
      return res?.data?.urls[0];
    } else {
      toast.error("Couldn't upload image.");
      return false;
    }
  };

  const handleAdminImageSelect = (e: any) => {
    setAdminImage(e.target.files[0]);
    const tempFile = e?.target?.files[0];
    if (tempFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setAdminPreviewUrl(reader?.result as string);
      };
      reader.readAsDataURL(tempFile);
    }
  };

  const uploadCenterImages = async () => {
    if (!centerImages) return;
    let imgs: string[] =
      completeCenterForm?.imageUrls && completeCenterForm?.imageUrls[0]
        ? completeCenterForm?.imageUrls
        : [];

    for (let i = 0; i < centerImages?.length; i++) {
      const formData = new FormData();
      formData.append("files", centerImages[i]);

      try {
        const res: any = await postFiles(formData);
        if (res?.ok) {
          const uploadedPath = res?.data?.urls;
          imgs = [...imgs, ...uploadedPath];
          setCompleteCenterForm((prev) => ({
            ...prev,
            imageUrls: [...prev?.imageUrls, uploadedPath],
          }));
        } else {
          toast.error("Couldn't upload image(s).");
          return false;
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        toast.error("Error uploading image.");
      }
    }
    return imgs;
  };

  const handleCenterImagesSelect = (e: any) => {
    const maxSizeMB = 5; // Maximum size in MB
    const maxSizeBytes = maxSizeMB * 1024 * 1024; // Convert MB to bytes

    const newFiles = Array.from(e?.target?.files) as File[];
    const validFiles = newFiles.filter((file) => file.size <= maxSizeBytes);

    if (validFiles.length !== newFiles.length) {
      toast.error(
        `Some files were not uploaded because they exceeded the ${maxSizeMB}MB limit.`
      );
    }

    if (centerImages?.length === 3) {
      toast.error("You can only upload up to 3 images.");
      return;
    }
    setCenterImages((prev) => [...prev, ...validFiles]);

    const newPreviewUrls: string[] = [];
    validFiles.forEach((file: File) => {
      const reader = new FileReader();
      reader.onload = () => {
        newPreviewUrls.push(reader?.result as string);
        if (newPreviewUrls.length === validFiles.length) {
          setCenterPreviewUrls((prev) => {
            const updatedUrls = prev.filter((url) => url !== ""); // Remove the initial empty string
            return [...updatedUrls, ...newPreviewUrls];
          });
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleCenterImageDelete = (index: number, isPrev?: boolean) => {
    if (isPrev) {
      setCompleteCenterForm((prev) => ({
        ...prev,
        imageUrls: prev.imageUrls.filter((_, i) => i !== index),
      }));
      return;
    }
    setCenterImages((prev) => prev.filter((_, i) => i !== index));
    setCenterPreviewUrls((prev) => prev.filter((_, i) => i !== index));
  };

  const handleCreateAdmin = async () => {
    let uploadedAdminImage: string = "";
    if (adminImage) {
      uploadedAdminImage = await uploadAdminImage();
      if (!uploadedAdminImage) return;
    }
    let reqBody: any = {
      name: completeCenterForm?.adminName,
      contactNumber: completeCenterForm?.adminPhoneNumber,
      profilePictureUrl:
        uploadedAdminImage || completeCenterForm?.adminProfilePictureUrl,
    };

    if (isEditing) {
      if (completeCenterForm?.email) {
        reqBody.email = completeCenterForm.email;
      }
      if (completeCenterForm?.password) {
        reqBody.password = completeCenterForm.password;
      }
    }

    const res = await completeProfile(
      redirectURI as string,
      clientID as string,
      reqBody
    );
    if (res?.ok) {
      setAuthCode(res?.data?.code);
    }
  };

  const handleCreateCenter = async () => {
    let uploadedCenterImages;
    if (centerImages && centerImages?.length > 0) {
      uploadedCenterImages = await uploadCenterImages();
      if (!uploadedCenterImages) return;
    }
    const reqBody = {
      centerName: completeCenterForm?.centerName,
      phoneNumber: completeCenterForm?.centerPhoneNumber,
      imageUrls:
        (uploadedCenterImages as string[]) || completeCenterForm?.imageUrls,
      location: {
        coordinates: completeCenterForm?.coordinates,
        address: completeCenterForm?.centerAddr,
        city: completeCenterForm?.city,
        state: completeCenterForm?.state,
        postalCode: completeCenterForm?.postalCode,
      },
      webUrl: completeCenterForm?.webUrl,
      placeId: completeCenterForm?.placeId,
      rating: completeCenterForm?.rating,
    };
    console.log("req body: ", reqBody);
    const res = isEditing
      ? await centerUpdate(reqBody)
      : await centerRegistration(reqBody);
    if (res?.ok) {
      window.location.replace(`${redirectURI}${"?authCode=" + authCode}`);
    }
  };

  useEffect(() => {
    if (!authCode) return;
    handleCreateCenter();
  }, [authCode]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    handleCreateAdmin();
  };

  const handleCancel = () => {
    window.location.replace(`${cancelURI}`);
  };

  useEffect(() => {
    if (location?.pathname === "/edit-center-profile") {
      setIsEditing(true);
    }
  }, []);

  //to set the data of user into form state to edit
  useEffect(() => {
    // if (!User?._id) return
    getAccountInfo();
  }, []);

  const getAccountInfo = async () => {
    const res = await fetchAccountInfo();
    if (res?.ok) {
      setCompleteCenterForm((prev) => ({
        ...prev,
        adminName: res?.data?.name,
        adminPhoneNumber: convertToE164(res?.data?.contactNumber, res.data.prevUser),
        adminProfilePictureUrl: res?.data?.profilePictureUrl,
        centerName: res?.data?.center?.centerName,
        centerPhoneNumber: convertToE164(res?.data?.center?.phoneNumber, res.data.prevUser),
        imageUrls: res?.data?.center?.imageUrls,
        coordinates: res?.data?.center?.location?.coordinates,
        centerAddr: res?.data?.center?.location?.address,
        city: res?.data?.center?.location?.city,
        state: res?.data?.center?.location?.state,
        postalCode: res?.data?.center?.location?.postalCode,
        webUrl: res?.data?.center?.webUrl,
        placeId: res?.data?.center?.placeId,
        rating: res?.data?.center?.rating,
        email: res?.data?.email,
      }));
    }
  };

  useEffect(() => {
    const fetchImageDetails = async () => {
      const details = await Promise.all(
        completeCenterForm?.imageUrls?.map(async (url) => {
          if (typeof url === "string") {
            const { actualImageName, imageSizeInKB } = await getImageDetails(
              url
            );
            return { url, actualImageName, imageSizeInKB };
          } else {
            console.error(`Invalid URL: ${url}`);
            return {
              url: "",
              actualImageName: "Invalid URL",
              imageSizeInKB: "N/A",
            };
          }
        })
      );
      setImageDetails(details);
    };

    if (completeCenterForm?.imageUrls?.length > 0) {
      fetchImageDetails();
    }
  }, [completeCenterForm?.imageUrls]);

  const getImageDetails = async (imageUrl: string) => {
    if (typeof imageUrl !== "string") {
      throw new Error("Invalid image URL type");
    }

    const imageName = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);
    const actualImageName = imageName.substring(
      imageName.lastIndexOf("-fileName-") + 10
    );
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const imageSize = blob.size;
    const imageSizeInKB = (imageSize / 1024).toFixed(2);

    return { actualImageName, imageSizeInKB };
  };
  //to check if there's an error and make submit button disable
  useEffect(() => {
    console.log("check form: ", completeCenterForm);
    const {
      imageUrls,
      adminProfilePictureUrl,
      password,
      confirmPassword,
      webUrl,
      ...filteredProfileForm
    } = completeCenterForm;

    const isFieldEmpty = Object.values(filteredProfileForm).some(
      (value) => value === "" || value === undefined || value === null
    );
    console.log("check empty: ", isFieldEmpty);

    const noError = Object.values(completeCenterFormErrors).every(
      (value) => value === "" || value === undefined || value === null
    );
    const isWrongAdminNumber =
      completeCenterForm?.adminPhoneNumber &&
      !isPossiblePhoneNumber(completeCenterForm?.adminPhoneNumber);
    const isWrongCenterNumber =
      completeCenterForm?.centerPhoneNumber &&
      !isPossiblePhoneNumber(completeCenterForm?.centerPhoneNumber);
    if (!isFieldEmpty && noError && !isWrongAdminNumber && !isWrongCenterNumber)
      setIsDisabled(false);
    else setIsDisabled(true);
  }, [completeCenterForm, completeCenterFormErrors]);

  const handleAutoCompleteLoad = useCallback(
    (autocomplete: google.maps.places.Autocomplete) => {
      setAutoComplete(autocomplete);
    },
    []
  );
  console.log("form: ", completeCenterForm);
  const onPlacesChanged = () => {
    if (autoComplete) {
      // console.log(autoComplete?.getPlace());
      // setPlaces(autoComplete?.getPlace());

      const place = autoComplete?.getPlace();
      console.log("place: ", place);
      const addressComponents = place?.address_components || [];
      const coordinates = [
        place?.geometry?.location?.lng() as number,
        place?.geometry?.location?.lat() as number,
      ]; // to make the behavior right for backend
      let city = "";
      let state = "";
      let postalCode = "";

      addressComponents?.forEach((component) => {
        const types = component.types;

        if (
          types.includes("locality") ||
          types.includes("sublocality_level_1")
        ) {
          city = component.long_name;
        }

        if (types.includes("administrative_area_level_1")) {
          state = component.long_name;
        }
        if (types.includes("postal_code")) {
          postalCode = component.long_name;
          setCompleteCenterFormErrors((prev) => ({
            ...prev,
            postalCodeError: "",
          }));
        }
      });

      setCompleteCenterForm((prev) => ({
        ...prev,
        centerAddr: place?.formatted_address || completeCenterForm?.centerAddr,
        city: city || prev.city,
        state: state || prev.state,
        coordinates: coordinates,
        rating: place?.rating || 0,
        placeId: place?.place_id || "",
        postalCode: postalCode || prev.postalCode,
      }));
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const verifyUserEmail = async (token: string) => {
    const res = await verifyEmail(token);
    console.log("check res: ", res);
  };

  useEffect(() => {
    if (!paramToken) return;
    const decodedToken: any = jwtDecode(paramToken);
    setCompleteCenterForm((prev) => ({ ...prev, email: decodedToken?.email }));
    verifyUserEmail(paramToken);
  }, [paramToken]);

  const handleSkip = () => {
    navigate(
      `/sign-in${clientID ? "?client_id=" + clientID : ""}${redirectURI ? "&redirect_URI=" + redirectURI : ""
      }`
    );
  };




  return (
    <>
      <div className="flex justify-center items-center bg-secondaryVariantNatural px-3 md:px-5 py-5">
        <div className="py-3 md:py-9 max-w-[1084px] w-full">
          <h3 className="text-secondary text-xl md:text-2xl font-semibold mb-5">
            {isEditing ? "Edit Profile" : "Complete Center Profile"}
          </h3>
          <form onSubmit={onSubmit} autoComplete="off">
            <h3 className="text-lg md:text-xl font-medium text-secondary mb-4">
              Center Admin Details
            </h3>
            <div className="flex md:flex-row flex-col gap-4 mb-4 md:mb-6 lg:mb-8">
              <div className="basis-1/3 ">
                <label className="text-base text-secondaryVariant">
                  Admin Name <RequiredAsterisk />
                </label>
                <input
                  type="text"
                  className="px-3 py-3.5 rounded-lg border border-secondaryVariant2 w-full bg-white mt-2"
                  placeholder="John Andrew"
                  name="adminName"
                  value={completeCenterForm?.adminName}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  autoComplete="off"
                />
                {completeCenterFormErrors?.adminNameError && (
                  <p className="text-red-600 pl-5">
                    {completeCenterFormErrors?.adminNameError}
                  </p>
                )}
              </div>
              <div className="basis-1/3 ">
                <label className="text-base text-secondaryVariant">
                  Admin Phone <RequiredAsterisk />
                </label>
                <Input
                  className="px-3 py-3.5 rounded-lg border border-secondaryVariant2 w-full bg-white mt-2"
                  country="US"
                  placeholder="(123) 456-7890"
                  value={completeCenterForm?.adminPhoneNumber}
                  autoComplete="off"
                  onChange={(value) => {
                    setCompleteCenterForm((prev) => ({
                      ...prev,
                      ["adminPhoneNumber"]: value || "",
                    }));
                  }}
                  error={
                    completeCenterForm?.adminPhoneNumber &&
                      !isPossiblePhoneNumber(completeCenterForm?.adminPhoneNumber)
                      ? "Invalid phone number"
                      : undefined
                  }
                />
                {completeCenterForm?.adminPhoneNumber &&
                  !isPossiblePhoneNumber(
                    completeCenterForm?.adminPhoneNumber
                  ) && (
                    <p className="text-red-600 pl-5">
                      {ERR_MSGS.Invalid_Phone_Number}
                    </p>
                  )}
              </div>{" "}
            </div>
            <div className="mb-6 md:mb-8 lg:mb-12">
              <label className="text-base text-secondaryVariant">
                Profile Picture (Optional)
              </label>
              <img
                src={
                  adminPreviewUrl ||
                  completeCenterForm?.adminProfilePictureUrl ||
                  "./images/empty-profile-img.png"
                }
                alt="profile"
                className="max-w-[110px] md:max-w-[175px] max-h-[110px] md:max-h-[175px] h-full object-cover mb-4"
              />
              <label className="text-base text-secondary border border-secondaryVariant2 flex items-center gap-2 bg-white rounded-full py-[5px] px-4 cursor-pointer w-max">
                <TbCamera className="w-6 h-6" />
                Upload/update{" "}
                <input
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleAdminImageSelect}
                />
              </label>{" "}
            </div>
            <hr className="mb-6 md:mb-8 lg:mb-12 text-secondaryVariant2" />
            <h3 className="text-xl md:text-2xl text-secondary font-medium mb-4">
              Center Details
            </h3>
            <div className="grid grid-cols-12 gap-4 md:gap-5 mb-11">
              <div className="col-span-12 md:col-span-4">
                <label className="text-base text-secondaryVariant">
                  Center Name <RequiredAsterisk />
                </label>
                <input
                  type="text"
                  name="centerName"
                  placeholder="Maryland, 10042 US"
                  className="px-3 py-3.5 rounded-lg border border-secondaryVariant2 w-full bg-white mt-2"
                  value={completeCenterForm?.centerName}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  autoComplete="off"
                />
                {completeCenterFormErrors?.centerNameError && (
                  <p className="text-red-600 pl-5">
                    {completeCenterFormErrors?.centerNameError}
                  </p>
                )}
              </div>
              <div className="col-span-12 md:col-span-4">
                <label className="text-base text-secondaryVariant">
                  Center Phone Number <RequiredAsterisk />
                </label>
                <Input
                  className="px-3 py-3.5 rounded-lg border border-secondaryVariant2 w-full bg-white mt-2"
                  country="US"
                  placeholder="(123) 456-7890"
                  autoComplete="off"
                  value={completeCenterForm?.centerPhoneNumber}
                  onChange={(value) => {

                    setCompleteCenterForm((prev) => ({
                      ...prev,
                      ["centerPhoneNumber"]: value || "",
                    }));
                  }}
                  error={
                    completeCenterForm?.centerPhoneNumber &&
                      !isPossiblePhoneNumber(
                        completeCenterForm?.centerPhoneNumber
                      )
                      ? "Invalid phone number"
                      : undefined
                  }
                />
                {completeCenterForm?.centerPhoneNumber &&
                  !isPossiblePhoneNumber(
                    completeCenterForm?.centerPhoneNumber
                  ) && (
                    <p className="text-red-600 pl-5">
                      {ERR_MSGS.Invalid_Phone_Number}
                    </p>
                  )}
              </div>
              <div className="col-span-12 md:col-span-4">
                <label className="text-base text-secondaryVariant">
                  Zip/Postal Code <RequiredAsterisk />
                </label>
                <input
                  type="text"
                  name="postalCode"
                  placeholder="54000"
                  className="px-3 py-3.5 rounded-lg border border-secondaryVariant2 w-full bg-white mt-2"
                  value={completeCenterForm?.postalCode}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  autoComplete="off"
                />
                {completeCenterFormErrors?.postalCodeError && (
                  <p className="text-red-600 pl-5">
                    {completeCenterFormErrors?.postalCodeError}
                  </p>
                )}
              </div>
            </div>
            <div className="grid grid-cols-12 gap-4 md:gap-5 mb-11">
              <div className="col-span-12 md:col-span-4">
                <label className="text-base text-secondaryVariant">
                  Address{" "}
                  <span className="text-xs text-secondaryVariant">
                    (Search a specific place)
                  </span>{" "}
                  <RequiredAsterisk />
                </label>
                {isLoaded && (
                  <Autocomplete
                    onLoad={handleAutoCompleteLoad}
                    onPlaceChanged={onPlacesChanged}
                  >
                    <input
                      // ref={inputRef}
                      type="text"
                      name="centerAddr"
                      placeholder="155-Zeplin, US 54000"
                      className="px-3 py-3.5 rounded-lg border border-secondaryVariant2 w-full bg-white mt-2"
                      value={completeCenterForm?.centerAddr}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                      autoComplete="off"
                    />
                  </Autocomplete>
                )}
                {completeCenterFormErrors?.addressError && (
                  <p className="text-red-600 pl-5">
                    {completeCenterFormErrors?.addressError}
                  </p>
                )}
              </div>
              <div className="col-span-12 md:col-span-4">
                <label className="text-base text-secondaryVariant">
                  Center Website
                </label>
                <input
                  // key={completeCenterForm?.webUrl}
                  type="text"
                  name="webUrl"
                  placeholder="www.xyz.com"
                  className="px-3 py-3.5 rounded-lg border border-secondaryVariant2 w-full bg-white mt-2"
                  value={completeCenterForm?.webUrl}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  autoComplete="off"
                />
                {completeCenterFormErrors?.webUrlError && (
                  <p className="text-red-600 pl-5">
                    {completeCenterFormErrors?.webUrlError}
                  </p>
                )}
              </div>
              {isEditing && (
                <div className="col-span-12 md:col-span-4">
                  <label className="text-base text-secondaryVariant">
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="email"
                    placeholder="xyz@gmail.com"
                    className="px-3 py-3.5 rounded-lg border border-secondaryVariant2 w-full bg-white mt-2 opacity-50"
                    value={completeCenterForm?.email}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    autoComplete="off"
                    disabled
                  />
                  {completeCenterFormErrors?.emailError && (
                    <p className="text-red-600 pl-5">
                      {completeCenterFormErrors?.emailError}
                    </p>
                  )}
                </div>
              )}
            </div>
            {isEditing && (
              <div className="grid grid-cols-12 gap-4 md:gap-5 mb-11">
                <div className="col-span-12 md:col-span-4">
                  <label className="text-base text-secondaryVariant">
                    Password
                  </label>
                  <input
                    // key={completeCenterForm?.password}
                    type="password"
                    name="password"
                    placeholder="Enter password"
                    className="px-3 py-3.5 rounded-lg border border-secondaryVariant2 w-full bg-white mt-2"
                    value={completeCenterForm?.password}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    autoComplete="new-password"
                  />
                  {completeCenterFormErrors?.passwordError && (
                    <p className="text-red-600 pl-5">
                      {completeCenterFormErrors?.passwordError}
                    </p>
                  )}
                </div>
                <div className="col-span-12 md:col-span-4">
                  <label className="text-base text-secondaryVariant">
                    Confirm Password
                  </label>
                  <input
                    // key={completeCenterForm?.confirmPassword}
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm password"
                    className="px-3 py-3.5 rounded-lg border border-secondaryVariant2 w-full bg-white mt-2"
                    value={completeCenterForm?.confirmPassword}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    autoComplete="new-password"
                  />
                  {completeCenterFormErrors?.confirmPasswordError && (
                    <p className="text-red-600 pl-5">
                      {completeCenterFormErrors?.confirmPasswordError}
                    </p>
                  )}
                </div>
              </div>
            )}

            <label className="text-base text-secondaryVariant">
              Center Pictures (Optional)
            </label>
            <div className="py-4 md:py-8 border border-[#D3D3D3] rounded-[10px] flex justify-center items-center mb-4 md:mb-8 flex-col gap-2 bg-[#F9FAFB]">
              <div className="flex flex-col justify-center relative">
                <input
                  type="file"
                  id="file"
                  className="relative"
                  accept="image/*"
                  multiple
                  hidden
                  onChange={handleCenterImagesSelect}
                />
                <label
                  htmlFor="file"
                  className="flex w-10 h-10 rounded-full bg-[#F2F2F2] items-center justify-center"
                >
                  <MdOutlineFileUpload className="w-6 h-6 text-secondary cursor-pointer" />
                </label>
              </div>
              {/* <button className="bg-[#F6F6F6] rounded-full p-2 absolute" type="button">
                </button> */}
              <h3 className="text-base text-secondary font-semibold">
                Choose/Upload Center Image
              </h3>
              <p className="text-sm text-[#555555]">5MB max file size</p>
            </div>
            {completeCenterForm?.imageUrls &&
              completeCenterForm.imageUrls.length > 0 && (
                <div className="flex flex-col sm:flex-row gap-4 mb-4 md:mb-8">
                  {imageDetails?.map((detail: any, index: number) => (
                    <div
                      key={index}
                      className="basis-1/3 p-2 flex items-center justify-between"
                    >
                      <div className="flex items-center gap-3">
                        <img
                          className="w-[55px] h-[55px]"
                          src={detail?.url || "./images/preview-img.png"}
                          alt="preview"
                        />
                        <p className="flex flex-col items-start text-base text-secondaryVariant">
                          {detail?.actualImageName}{" "}
                          <span>{detail?.imageSizeInKB}kb</span>
                        </p>
                      </div>
                      <button
                        className="text-secondary hover:text-red-400"
                        type="button"
                        onClick={() => handleCenterImageDelete(index, true)}
                      >
                        <RiDeleteBinLine className="w-6 h-6" />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            {centerPreviewUrls[0] && centerPreviewUrls?.length > 0 && (
              <div className="flex flex-col md:flex-row gap-4 mb-4 md:mb-8">
                {centerPreviewUrls?.map((url, index) => (
                  <div className=" p-2 flex basis-1/3 items-center justify-between">
                    <div className="flex items-center gap-3">
                      <img
                        className="w-[55px] h-[55px]"
                        src={url || "./images/preview-img.png"}
                        alt="preview"
                      />
                      <p className="flex flex-col items-start text-base text-secondaryVariant max-w-[220px] truncate">
                        {centerImages[index]?.name}{" "}
                        <span>
                          {(centerImages[index]?.size / 1024).toFixed(2)}kb
                        </span>{" "}
                      </p>
                    </div>
                    <button
                      className="text-secondary hover:text-red-400"
                      type="button"
                      onClick={() => {
                        handleCenterImageDelete(index);
                      }}
                    >
                      <RiDeleteBinLine className="w-6 h-6" />
                    </button>
                  </div>
                ))}
              </div>
            )}
            <div className="flex items-center gap-3">
              <button
                className={`btnPrimary max-w-[156px] ${isDisabled ? "opacity-50" : ""
                  }`}
                type="submit"
                disabled={isDisabled}
              >
                Save
              </button>
              {/* {!isEditing && (
                  <button
                    className="btnSimple max-w-[86px]"
                    type="button"
                    onClick={handleSkip}
                  >
                    Skip
                  </button>
                )} */}
              {isEditing && (
                <button
                  className="btnSimple max-w-[156px]"
                  type="button"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              )}
            </div>
          </form>
        </div>
      </div>{" "}
    </>
  );
};

export default CompleteCenterRegistration;
